<template>
    <div></div>
</template>

<script>
export default {
    beforeCreate(){
        setTimeout(() => {
            parent?.window?.authComplete?.(this.$route.query);
            opener?.window?.authComplete?.(this.$route.query);
            window.close();
        }, 500);
    }
}
</script>